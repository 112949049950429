<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-img src="/logo2.png" max-height="30" max-width="30" class="mr-3" />

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group
          v-if="item.children"
          :key="`group-${i}`"
          :item="item"
        ></base-item-group>
        <base-item v-else-if="item.visible" :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    items: [
      {
        icon: "mdi-view-dashboard",
        title: "dashboard",
        to: "/",
      },
      {
        icon: "mdi-format-list-bulleted-square",
        title: "user.list",
        to: "/pages/user-list",
        permission: ["edit users"],
      },
      {
        title: "transactions.list",
        icon: "mdi-bank-transfer",
        to: "/pages/transactions-list",
        permission: ["view transactions"],
      },
      {
        title: "transactions.bookings list",
        icon: "mdi-cash-plus",
        to: "/pages/bookings-list",
        permission: ["view transactions"],
      },
      {
        title: "transactions.fees list",
        icon: "mdi-account-cash",
        to: "/pages/fees-list",
        permission: ["view fees"],
      },
      {
        title: "Banks",
        icon: "mdi-bank",
        to: "/pages/banks",
        permission: ["banks"],
      },
      {
        title: "bank transfers.bank transfers",
        icon: "mdi-bank-transfer",
        to: "/pages/bank/transfers",
        permission: ["bank transfers"],
      },
      {
        title: "pay.Pay-in Details",
        icon: "mdi-credit-card-settings-outline",
        to: "/pages/payment-details",
        permission: ["edit payin details"],
      },
      {
        title: "identities.title",
        icon: "mdi-card-account-details-outline",
        to: "/pages/kyc/identities-list",
        permission: ["edit identities"],
      },
      {
        title: "user.administrators",
        icon: "mdi-account-multiple",
        to: "/pages/admins",
        permission: ["edit admins"],
      },
      {
        title: "roles and permissions.page title",
        icon: "mdi-account-key",
        to: "/pages/roles",
        permission: ["edit roles"],
      },
      {
        title: "Debug",
        icon: "mdi-cog",
        group: "/debug",
        permission: ["debug"],
        children: [
          {
            title: "Routes",
            to: "routes",
          },
        ],
      },
    ],
  }),

  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    mapItem(item) {
      const hasPermission = this.$store.getters.menuHasPermission(item);
      return {
        ...item,
        visible: hasPermission,
        children:
          hasPermission && item.children
            ? item.children.map(this.mapItem)
            : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
      margin-right: 24px
      margin-left: 12px !important

      +rtl()
      margin-left: 24px
      margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
      padding-left: 8px

      +rtl()
      padding-right: 8px

    .v-list-group__header
      +ltr()
      padding-right: 0

      +rtl()
      padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
        margin-right: 8px

        +rtl()
        margin-left: 8px
</style>
